.fc .fc-toolbar.fc-header-toolbar {
  padding-top: 15px;
  display: flex;
}
.fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
  display: flex;
}
.fc .fc-toolbar .fc-toolbar-chunk > div:first-child {
  display: flex;
}
.fc .fc-toolbar .fc-toolbar-title {
  margin-left: 8px;
  margin-right: 8px;
}

:root {
  --fc-button-bg-color: var(--fg-color);
  --fc-button-border-color: var(--gray-300);
  --fc-button-text-color: var(--gray-900);
  --fc-button-active-bg-color: var(--gray-300);
  --fc-button-active-border-color: var(--gray-300);
  --fc-button-hover-bg-color: #ffff;
  --fc-button-hover-border-color: #4C5A67;
}

:root {
  --primary: var(--blue-800);
  --btn-primary: var(--blue-800);
}